import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Software/Linux/MotionEye/DangerBox';
import NavButtons from 'components/Software/Linux/NavButtons';
import ForumBox from 'components/Software/Linux/MotionEye/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Motion on Debian",
  "path": "/Software/Linux/MotionEye/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "Motion is a highly configurable program that monitors video signals from many types of cameras (Network cameras via HTTP, RTSP and RTMP). Set it up to monitor your INSTAR IP camera, watch birds, check in on your pet, create timelapse videos and more. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_motionEye.png",
  "social": "/images/Search/AU_SearchThumb_motionEye.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_motionEye_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "motionEye"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Motion on Debian' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Motion is a highly configurable program that monitors video signals from many types of cameras (Network cameras via HTTP, RTSP and RTMP). Set it up to monitor your INSTAR IP camera, watch birds, check in on your pet, create timelapse videos and more. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_motionEye.png' twitter='/images/Search/AU_SearchThumb_motionEye.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Linux/MotionEye/' locationFR='/fr/Software/Linux/MotionEye/' crumbLabel="MotionEye" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#linux",
        "aria-label": "linux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LINUX`}</h2>
    <h3 {...{
      "id": "motioneye",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#motioneye",
        "aria-label": "motioneye permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MotionEye`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#debian-installation"
        }}>{`Debian Installation`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ssh-access"
            }}>{`SSH Access`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#set-a-static-ip-address"
            }}>{`Set a static IP Address`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#motioneye-installation"
        }}>{`motionEye Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-your-ip-camera"
        }}>{`Adding your IP Camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#enabling-actions"
            }}>{`Enabling Actions`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#http-request-example"
                }}>{`HTTP Request Example`}</a>
                <ul parentName="li">
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#alarm---full-hd-models"
                    }}>{`Alarm - Full HD Models`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#alarm---vga-models"
                    }}>{`Alarm - VGA Models`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#ptz---full-hd-models"
                    }}>{`PTZ - Full HD Models`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#preset-positions---full-hd-models"
                    }}>{`Preset Positions - Full HD Models`}</a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-motioneye"
        }}>{`Configuring MotionEye`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#accessing-motioneye-over-https"
        }}>{`Accessing MotionEye over HTTPS`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#installation-of-firewalld-on-debian"
            }}>{`Installation of FirewallD on Debian`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#installation-of-nginx-on-debian"
            }}>{`Installation of NGINX on Debian`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#set-up-a-virtual-host-for-motioneye"
                }}>{`Set up a Virtual Host for MotionEye`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#create-the-ssl-certificate"
                }}>{`Create the SSL Certificate`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#configure-nginx-to-use-ssl"
                }}>{`Configure Nginx to Use SSL`}</a>
                <ul parentName="li">
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#adjust-the-nginx-configuration-to-use-ssl"
                    }}>{`Adjust the Nginx Configuration to Use SSL`}</a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <p><a parentName="p" {...{
        "href": "https://motion-project.github.io"
      }}>{`Motion`}</a>{` is a highly configurable program that monitors video signals from many types of cameras (Network cameras via HTTP, RTSP and RTMP). Set it up to monitor your INSTAR IP camera, watch birds, check in on your pet, create timelapse videos and more.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/ccrisan/motioneye/wiki"
      }}>{`motionEye`}</a>{` is a web frontend for the motion daemon, written in Python.`}</p>
    <p>{`We are going to install both on a regular Intel Core machine - like an `}<a parentName="p" {...{
        "href": "https://www.intel.com/content/www/us/products/boards-kits/nuc/kits.html"
      }}>{`Intel NUC`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.asus.com/Mini-PCs/Mini-PC-PN60/"
      }}>{`Asus P-Series`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.gigabyte.com/Mini-PcBarebone/BRIX"
      }}>{`Gigabyte Brix`}</a>{`. This decision was made due to the high CPU demands of working with 1080p video.`}</p>
    <p>{`But we already wrote a tutorial on how to install `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/#installation-of-motioneye"
      }}>{`motionEye on a Raspberry Pi`}</a>{` in case that this amount of processing power is sufficient for your use case.`}</p>
    <h2 {...{
      "id": "debian-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#debian-installation",
        "aria-label": "debian installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Debian Installation`}</h2>
    <p>{`We choose to install `}<strong parentName="p">{`Debian 9 Linux`}</strong>{` on our mini PC - you can check the `}<a parentName="p" {...{
        "href": "https://github.com/ccrisan/motioneye/wiki/Installation"
      }}>{`motionEye Wiki`}</a>{` for more Linux flavours. To create the installation USB stick we `}<a parentName="p" {...{
        "href": "https://www.debian.org/CD/netinst/"
      }}>{`downloaded the minimal amd64 image`}</a>{` and used the tool `}<strong parentName="p">{`Etcher`}</strong>{` to prepare the USB stick. We used the same approach to prepare a SD card with the openHABian image - you can just `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/#install-openhabian-on-a-raspberry-pi"
      }}>{`follow the steps from that tutorial`}</a>{`. Once the USB stick is ready, deactivate secure boot in your mini PC's BIOS and boot from the stick. We are not going to need a desktop environment. But `}<strong parentName="p">{`make sure that you install the SSH service`}</strong>{` - as we are going to use the SSH service to set up the Debian server.`}</p>
    <h3 {...{
      "id": "ssh-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ssh-access",
        "aria-label": "ssh access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSH Access`}</h3>
    <p>{`To enable SSH login for a root user on Debian Linux system you need to first configure SSH server. Open `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/ssh/sshd_config`}</code>{` and change the following line:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`FROM:
PermitRootLogin without-password
TO:
PermitRootLogin `}<span parentName="code" {...{
            "className": "token function"
          }}>{`yes`}</span></code></pre></div>
    <p>{`Once you made the above change restart your SSH server:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# /etc/init.d/ssh restart`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` ok `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Restarting `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ssh`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`via systemctl`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`: ssh.service.`}</code></pre></div>
    <p>{`From now on you will be able to ssh login as a root:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ssh`}</span>{` root@192.168.2.111
root@192.168.2.111's password: 

The programs included with the Debian GNU/Linux system are `}<span parentName="code" {...{
            "className": "token function"
          }}>{`free`}</span>{` software`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
the exact distribution terms `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` each program are described `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` the
individual files `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` /usr/share/doc/*/copyright.

Debian GNU/Linux comes with ABSOLUTELY NO WARRANTY, to the extent
permitted by applicable law.`}</code></pre></div>
    <h3 {...{
      "id": "set-a-static-ip-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-a-static-ip-address",
        "aria-label": "set a static ip address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set a static IP Address`}</h3>
    <p>{`By default, you will find the following configuration within the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/network/interfaces`}</code>{` network config file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`source /etc/network/interfaces.d/*

# The loopback network interface
auto lo
iface lo inet loopback

# The primary network interface
allow-hotplug eth0
iface eth0 inet static
      address 192.168.2.111
      netmask 255.255.255.0
      gateway 192.168.2.1`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`To configure a static DNS edit `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/resolv.conf`}</code>{` file, and include the IP address of your preferred nameserver eg:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`nameserver 8.8.8.8`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "motioneye-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#motioneye-installation",
        "aria-label": "motioneye installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`motionEye Installation`}</h2>
    <p><em parentName="p">{`All commands require root; use sudo before each command or become root using su.`}</em></p>
    <ol>
      <li parentName="ol">{`Install motion, ffmpeg and v4l-utils:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` motion ffmpeg v4l-utils`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Install the dependencies from the repositories:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` python-pip python-dev python-setuptools `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` libssl-dev libcurl4-openssl-dev libjpeg-dev libz-dev`}</code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Install motioneye, which will automatically pull Python dependencies (tornado, jinja2, pillow and pycurl):`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`pip `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` motioneye`}</code></pre></div>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Prepare the configuration directory:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /etc/motioneye
 `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cp`}</span>{` /usr/local/share/motioneye/extra/motioneye.conf.sample /etc/motioneye/motioneye.conf`}</code></pre></div>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Prepare the media directory:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /var/lib/motioneye`}</code></pre></div>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Add an init script, configure it to run at startup and start the motionEye server:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cp`}</span>{` /usr/local/share/motioneye/extra/motioneye.systemd-unit-local /etc/systemd/system/motioneye.service
  systemctl daemon-reload
  systemctl `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`enable`}</span>{` motioneye
  systemctl start motioneye`}</code></pre></div>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`To upgrade to the newest version of motioneye, after it has been released, just issue:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`pip `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` motioneye --upgrade
systemctl restart motioneye`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "adding-your-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-ip-camera",
        "aria-label": "adding your ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your IP Camera`}</h2>
    <p>{`Open the MotionEye Interface with your web browsers by typing in your Raspberry Pi's IP address followed by the Port 8765 - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.115:8765`}</code>{`. The default login is `}<strong parentName="p">{`admin`}</strong>{` `}<strong parentName="p">{`without a password`}</strong>{`. We already covered how `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/#adding-your-ip-camera"
      }}>{`to add JPG, MJPEG and RTSP cameras`}</a>{` earlier. Follow the instructions there and add all your INSTAR VGA, HD and Full HD cameras.`}</p>
    <p>{`We now want to go a step further and add buttons to control the basic camera functions. MotionEye offers a list of `}<strong parentName="p">{`Action Buttons`}</strong>{` that we can use with our camera's `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`CGI commands`}</a>{`. They automatically map to Shell Scripts that we have to put into the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/motioneye`}</code>{` directory. The following actions are defined:`}</p>
    <ul>
      <li parentName="ul">{`lock`}</li>
      <li parentName="ul">{`unlock`}</li>
      <li parentName="ul">{`light_on`}</li>
      <li parentName="ul">{`light_off`}</li>
      <li parentName="ul">{`alarm_on`}</li>
      <li parentName="ul">{`alarm_off`}</li>
      <li parentName="ul">{`up`}</li>
      <li parentName="ul">{`right`}</li>
      <li parentName="ul">{`down`}</li>
      <li parentName="ul">{`left`}</li>
      <li parentName="ul">{`zoom_in`}</li>
      <li parentName="ul">{`zoom_out`}</li>
      <li parentName="ul">{`preset1 to preset9`}</li>
    </ul>
    <p>{`While the available actions are limited to the above set, the commands executed can be practically anything. We will choose to execute HTTP GET commands to send `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`CGI commands`}</a>{` to our cameras.`}</p>
    <h3 {...{
      "id": "enabling-actions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#enabling-actions",
        "aria-label": "enabling actions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Enabling Actions`}</h3>
    <p>{`motionEye will look inside its configuration folder `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/motioneye`}</code>{` for executable files named `}{`[action]`}{`_`}{`[cameraid]`}{`, where action is one of the available actions (listed above) and cameraid is the id of the camera on top of which the action button will be displayed.`}</p>
    <p>{`For example, on a setup using the default configuration, the presence of the executable file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/motioneye/alarm_on_1`}</code>{` tells motionEye to show an alarm bell button for the camera number one. The file will be executed upon pressing the button. Buttons will have distinctive icons that correspond to the name of the action.`}</p>
    <h4 {...{
      "id": "http-request-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#http-request-example",
        "aria-label": "http request example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTTP Request Example`}</h4>
    <p>{`Let's say that you want to issue an HTTP request to your INSTAR IP camera when you click the "turn alarm on" button of your first camera (with id 1). Create the following bash script and make it executable:`}</p>
    <h5 {...{
      "id": "alarm---full-hd-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#alarm---full-hd-models",
        "aria-label": "alarm   full hd models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm - Full HD Models`}</h5>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6153e935bad5469dc88d7e37d44750da/e9beb/MotionEye_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB4UlEQVQY0yXIXWvTUACA4dg2a+1qG5v0A4t0w2q1bdKm5uScfOckMWmt3ZUIXnkrA0EERVCQ6fQfeOHfEW/m/aAKMp1bh2trS1IEx9rIEF6ei5f4+vHT8eDLeHj0909w98nTGAsKqlOQzaKk5QWFbsELTZDg/ieQDRBvolRbibfk87xC/Dgafjs8/PX+QzgY3H+xRVS5ite/3t0oKHaqLeYkMyfhLMRpQcvJJiMZNDTSNxWSQyscInZ3Bzs7n3/u74fh8t7jZ0SVL+kuLVnJtpqFRl62s7Kdly0GmVnFyiAzxSurvBKtAZKFxMHBcO/73mh0HIaLB89fEjd4GpmUaNCSRSNMAWNV0DOiyUjWRYQpeHYoYMRYGGMREQR+EATT6SRcnjza3maQUnG763a3jL0y7lTc3lW3X8ZeUXUuaU5RdRgJJ3mZZGGkLhKz2Wz8ezIaj5anJw9fbaWBvG531rC7ht2S5hRlq6Q6JcOlRIORTUrUk2013kQkC+IcJObzwPf9yXQaLk4337y7rFmW18e3N8zuHcn2WqrVELUqJ2V4NcGhaAOssJBkYbQBEi1I+P5sPp8HgR+Gi83Xb68gXTU7vNsDXq9hOHXzVl03rnHCuboQqQmRGojUhGj9TJIF/wCHQaLDXPtnhQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6153e935bad5469dc88d7e37d44750da/e4706/MotionEye_01.avif 230w", "/en/static/6153e935bad5469dc88d7e37d44750da/d1af7/MotionEye_01.avif 460w", "/en/static/6153e935bad5469dc88d7e37d44750da/b6582/MotionEye_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6153e935bad5469dc88d7e37d44750da/a0b58/MotionEye_01.webp 230w", "/en/static/6153e935bad5469dc88d7e37d44750da/bc10c/MotionEye_01.webp 460w", "/en/static/6153e935bad5469dc88d7e37d44750da/87ca7/MotionEye_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6153e935bad5469dc88d7e37d44750da/81c8e/MotionEye_01.png 230w", "/en/static/6153e935bad5469dc88d7e37d44750da/08a84/MotionEye_01.png 460w", "/en/static/6153e935bad5469dc88d7e37d44750da/e9beb/MotionEye_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6153e935bad5469dc88d7e37d44750da/e9beb/MotionEye_01.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` /etc/motioneye/alarm_on_1
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` +x /etc/motioneye/alarm_on_1
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/motioneye/alarm_on_1`}</code></pre></div>
    <p>{`Then type in (or paste) the following contents, save and exit nano (Ctrl-O, Enter, Ctrl-X):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.113/param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/302224711da70bd5e0bfd5b0d54690b6/e9beb/MotionEye_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA/UlEQVQI12O4fuvm3Wd3n796/urtu5zWFgP/YNPgCH3/EIOAUOOAUKOAYD2/AC3vAB2fIMPAUBAKCDXwD9X2DtDzC2Z4cOXq6+s33794+fffn5zmNnFrB3EbZw4DCx4jK04tUy5tE15jKy4jGy5Da25DSx5DS15jK15ja34zez5Te4Y7h45eXLP+1b37////z6qv5TQwE7dx4TOxEzCzE7a0F7KwFzC14zOx5Ta2FjS24dAxY9UzY9U1Y9O1YNOzYLh68/aps+ceP336////iKIKBjlNdj1LNj0LFh1zFl0LJi0zJm0zFm0zZh0zFl0LFl1zFh1zVhBpxqprAQAj+FB5SehfVwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/302224711da70bd5e0bfd5b0d54690b6/e4706/MotionEye_02.avif 230w", "/en/static/302224711da70bd5e0bfd5b0d54690b6/d1af7/MotionEye_02.avif 460w", "/en/static/302224711da70bd5e0bfd5b0d54690b6/b6582/MotionEye_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/302224711da70bd5e0bfd5b0d54690b6/a0b58/MotionEye_02.webp 230w", "/en/static/302224711da70bd5e0bfd5b0d54690b6/bc10c/MotionEye_02.webp 460w", "/en/static/302224711da70bd5e0bfd5b0d54690b6/87ca7/MotionEye_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/302224711da70bd5e0bfd5b0d54690b6/81c8e/MotionEye_02.png 230w", "/en/static/302224711da70bd5e0bfd5b0d54690b6/08a84/MotionEye_02.png 460w", "/en/static/302224711da70bd5e0bfd5b0d54690b6/e9beb/MotionEye_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/302224711da70bd5e0bfd5b0d54690b6/e9beb/MotionEye_02.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We will use the `}<a parentName="p" {...{
        "href": "/en/1080p_Series_CGI_List/Alarm_Menu/Schedule/"
      }}>{`Alarm Schedule CGI Command`}</a>{` for our INSTAR Full HD Camera in this example - but the URL can be set to any `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`CGI command`}</a>{` that you need!`}</p>
    <p>{`Note that the command activates the motion alarm detection of your camera 24/7. If you are already using a alarm schedule that deactivates your camera alarm during certain times - just replace some of those `}<strong parentName="p">{`P`}</strong>{`'s with `}<strong parentName="p">{`N`}</strong>{`'s to reflect your personal schedule and set MotionEye up to switch between `}<strong parentName="p">{`Alarm Always ON`}</strong>{` / `}<strong parentName="p">{`Your Alarm Schedule`}</strong>{`.`}</p>
    <p>{`To switch the alarm off we repeat those steps for:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` /etc/motioneye/alarm_off_1
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` +x /etc/motioneye/alarm_off_1
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/motioneye/alarm_off_1`}</code></pre></div>
    <p>{`Then type in (or paste) the following contents, save and exit nano (Ctrl-O, Enter, Ctrl-X):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.113/param.cgi?cmd=setscheduleex&-ename=md&-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <p>{`Reload the MotionEye interface - the buttons should now be visible in the camera 1 view port (you might have to click onto the video are once to have them appear). If the buttons are not displayed, make sure that you made the shell scripts executable.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/427e62bb3223d8e4e664cfe7d2d1de23/e9beb/MotionEye_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD4ElEQVQ4y2XO6VMbdRjA8f0HtDqdEa1IIEA2WIqQYzchBzk4yhVINtnc9272+u1u9k6CAeTSF874zjc6tTCUKjja6Zsy1s4oLVIOZ6z0D3JAp2/8zDPPu+88D4Qn4tHFhUQc297ZOTu/OD4+OT27eP7i+Lej53+cvDw5PXt1+fqvvy9fXb4+P784Pv3z62/3xu+WUulMMpmEnHcTjnDEEV5wOhyoy4UgCPqGy3W9rqAoGh4fX1hcnJmenZqampyYIEkCgpEAbPMMOP0wbIFh2Pp/sBWGYYvFgqKuicmpcHg8GAoHgqG5SARCnI5QwB8KjPl83nAo9PGAtb+vr7//at7ovdZtNptu27qHPV1D7k7ryC2rDQq43flsKp9L18iKVOfroiCKQBSAwAPAsQxD0zRVq5EESVSJaoUgSwRRqFQKpXIcT0I+p7NSzDJUhQeUKgutpt5qas2mZhiKpsmqqmiaqumqqimKKktKXZREIPAcz5VKZWg2GN7a3GSoWjaDZ7J4HMcymVQ2k84Vc2WiTNI1iqE4ngMCAALgeMBwbI2hCYrMFQpQfHpu+/697+59Q1PktN+XmJgopnGWJappPDczXcKiPM8qiiwrsijJvCQBUWA4jmLYYqkMpaLY7u79B3vbm+vt+WH7rBlOz0xyfDUb8s/3mKMjwyxTU3VN03VZUQVJFkSRAYBmr9+u5Iv7B3sP93c/W1mK2RBs8JPcwhxBFjJ+b9xijTudDEXquqEbDUXVRUkR6hILeJoD+UIJYmvMwU8HOw+2l1tGwoYmhkZKiVixnEu63VgfjDmcNE3qhmE0moqmi7LM1yWW52kAsvkipIjC4dNfHj853FhdTSHelN2lAE5SxJR7NGq2YE6Epmu6YTQaTVU3JFkRJIkTBIYXcsUyVEwmHn6//+zFy/WVtQwazLj8S41Ge6Wd9Y5FzBYMRWmaur7cUnSjrqi8JLNXsXgVLy+v7P/46Onvx3VWSDp8acTLkgTL0jjiXuiFYy43zVCaYWjNpqIZdfnfWPwvXtvY+OHg0eGzI0ByuN2LOz1MjWFoLm5HI71w1D1K0ZSi62qjIamaIMmgXmd4keaEXKEEbXy+9fPjJ78enWhAzSHBnCe4srrZXt5IIZ6Y5XZyLMAB0Gx92my39daSbDQkTWdlhRHlbJmAitXy2vrWF19+FY/Eg5ah8OBIhaDy+cqYZWD0Q5P/ztB8ZD6ZTifTmUQyFUvgkRhGxPAqli7MLELWgQG7ze7x+qww3HHj3c73Osy9fT095o4b73S89XbHzZudnZ0mU7fJZOrqMn3UZXr/1geLfYNzd0ZjVts/exeMGBxS8LMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/427e62bb3223d8e4e664cfe7d2d1de23/e4706/MotionEye_03.avif 230w", "/en/static/427e62bb3223d8e4e664cfe7d2d1de23/d1af7/MotionEye_03.avif 460w", "/en/static/427e62bb3223d8e4e664cfe7d2d1de23/b6582/MotionEye_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/427e62bb3223d8e4e664cfe7d2d1de23/a0b58/MotionEye_03.webp 230w", "/en/static/427e62bb3223d8e4e664cfe7d2d1de23/bc10c/MotionEye_03.webp 460w", "/en/static/427e62bb3223d8e4e664cfe7d2d1de23/87ca7/MotionEye_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/427e62bb3223d8e4e664cfe7d2d1de23/81c8e/MotionEye_03.png 230w", "/en/static/427e62bb3223d8e4e664cfe7d2d1de23/08a84/MotionEye_03.png 460w", "/en/static/427e62bb3223d8e4e664cfe7d2d1de23/e9beb/MotionEye_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/427e62bb3223d8e4e664cfe7d2d1de23/e9beb/MotionEye_03.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h5 {...{
      "id": "alarm---vga-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#alarm---vga-models",
        "aria-label": "alarm   vga models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm - VGA Models`}</h5>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` /etc/motioneye/alarm_on_3
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` +x /etc/motioneye/alarm_on_3
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/motioneye/alarm_on_3`}</code></pre></div>
    <p>{`Then type in (or paste) the following contents, save and exit nano (Ctrl-O, Enter, Ctrl-X):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.117/set_alarm.cgi?motion_armed=1"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` /etc/motioneye/alarm_off_3
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` +x /etc/motioneye/alarm_off_3
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/motioneye/alarm_off_3`}</code></pre></div>
    <p>{`Then type in (or paste) the following contents, save and exit nano (Ctrl-O, Enter, Ctrl-X):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.117/set_alarm.cgi?motion_armed=0"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <h5 {...{
      "id": "ptz---full-hd-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#ptz---full-hd-models",
        "aria-label": "ptz   full hd models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PTZ - Full HD Models`}</h5>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` /etc/motioneye/left_2 /etc/motioneye/right_2 /etc/motioneye/down_2 /etc/motioneye/up_2
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` +x /etc/motioneye/left_2 /etc/motioneye/right_2 /etc/motioneye/down_2 /etc/motioneye/up_2`}</code></pre></div>
    <p><strong parentName="p">{`STEP LEFT`}</strong></p>
    <p><em parentName="p">{`left_2`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.116/ptzctrl.cgi?-step=&-act=left"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <p><strong parentName="p">{`STEP RIGHT`}</strong></p>
    <p><em parentName="p">{`right_2`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.116/ptzctrl.cgi?-step=&-act=right"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <p><strong parentName="p">{`STEP DOWN`}</strong></p>
    <p><em parentName="p">{`down_2`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.116/ptzctrl.cgi?-step=&-act=down"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <p><strong parentName="p">{`STEP UP`}</strong></p>
    <p><em parentName="p">{`up_2`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.116/ptzctrl.cgi?-step=&-act=up"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <h5 {...{
      "id": "preset-positions---full-hd-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#preset-positions---full-hd-models",
        "aria-label": "preset positions   full hd models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preset Positions - Full HD Models`}</h5>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` /etc/motioneye/preset1_2 /etc/motioneye/preset2_2
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` +x /etc/motioneye/preset1_2 /etc/motioneye/preset2_2`}</code></pre></div>
    <p><strong parentName="p">{`Go to Preset 1`}</strong></p>
    <p><em parentName="p">{`preset1_2`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.116/param.cgi?cmd=preset&-act=goto&-number=0"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <p><strong parentName="p">{`Go to Preset 2`}</strong></p>
    <p><em parentName="p">{`preset2_2`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`URL`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://admin:instar@192.168.2.116/param.cgi?cmd=preset&-act=goto&-number=1"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`METHOD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"GET"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TIMEOUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$METHOD`}</span>{` --connect-timeout `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$TIMEOUT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$URL`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6df18c836223b4d15ab7c47b20794f56/e9beb/MotionEye_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "86.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD9ElEQVQ4yxXM308adwAA8Hvd0kWdFjy4O+64w+/9Po47fqmg/JSKggoVBBW1ulqx0mpx2mqscU5bo3ZdW3+gFgFnmy3ZMtesWdI0dg97WLJkybKHZX3zcX/E0j/g84E6uq9G48mFpfuHB4f7z3b2dnb39guFwsFR4eD5wWGxWDwulUvlSqlcOS6Vj8ulYqlyMzfXEU0MDA5AbGObEugRLFYcRXGdnkBRkiSNOIHCMFxXh8AwRVEkSZIUSRAEx/GtHq/X5/OG2uOJFMRxnKwqDEMDYPq0uookSU6WWEngJLGBoREUrautxTCUMBoBQzMcY+RkA6fqcKAjAKQ67K6AX7ZIAkdzRiI9kJqcz1+fnsrmb0/dzc8szQevtMVi0cFMqj0c8gd8rmZHdzSkEJhC4ZDd1dzo85olXmQZC00PjQ7fyOfGctmJ/PTNuTvzq8uJdF//YHptc/3aZ9esEu8UmESyq5kFNgKDHC0uZ4tL4hiBBXZBXFhbW9zanJidHcpOZLI3pu7NxxKJnnjPwydfbe8+iwR8NhMZ7Q63SLwdxyC7u8Xe3CQyNE/TLkV9eHD0/NX54dnb/R9e751+u/p0Pz081tHZubq1sbW3k0ml3DTT1dXhsYhuwvAB25wOkQVCA/A4nA8O95++fFU4O3/z5z9/vL/4++K/3eI3fYMjO6XKdqEwNTnVYgKTuelUPG6nKcjZ6rXarSJLC6Ah6HZvn5QeVV58/fLHw7M3R6/Pf39/Ufn+p+zM5+/++vfk519W1h9GFHtvIiXbrSiBQk1er6JaRBaYaeBxOsdu3RqfuXN7aXlp6/FW+eT019++3D1I9g999/bd7umL/N1FRZL0mB4h9DhpgJr8AcUiizxrBg1Br2d0dn5y8YuFjcfLT/YXHmzPrayOTOYikej99bVUepgReJRAcNKAU7iBxKBGr0eWRUniZGAKBfyZ3NRoPn9vfWOlWNk8KufGs8HWQNeV9lh3H2A5EhhxyoARKIIjiEEPWZsbZTNvNosKMAWD/p7hTMfVWCweDyZ7+9Lp6fRQpj3S5Q/Z3K0mHlCsCTOiBiNCApyTWMjualRkQVVlG2MKeH2+SNQXDEXC0XA6NTZ+faY3ORYKO1XVyNFAYAjaaORJRuFNEotzFGSxqSJHCwKrNJBBbyAY6gy3d3e0dYZjvUMjY9lEf7e/jaRNBE1RXIPVoXrsFq9NDTY7w24XhGAogRu0mrq6Ty7Bep0exzGcRBAUIQgKAIECdZcvf3Tp4+qaqlpdPa2DZU5heZW2upOiA9LpdRiG6GBYU1Oj0Wi0MKzRauvh+lqN5kOl11fVVFdVV2m02lqtloF1DmA2m0TSSPdQ/P/fyi6g6Hr6YgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6df18c836223b4d15ab7c47b20794f56/e4706/MotionEye_04.avif 230w", "/en/static/6df18c836223b4d15ab7c47b20794f56/d1af7/MotionEye_04.avif 460w", "/en/static/6df18c836223b4d15ab7c47b20794f56/b6582/MotionEye_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6df18c836223b4d15ab7c47b20794f56/a0b58/MotionEye_04.webp 230w", "/en/static/6df18c836223b4d15ab7c47b20794f56/bc10c/MotionEye_04.webp 460w", "/en/static/6df18c836223b4d15ab7c47b20794f56/87ca7/MotionEye_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6df18c836223b4d15ab7c47b20794f56/81c8e/MotionEye_04.png 230w", "/en/static/6df18c836223b4d15ab7c47b20794f56/08a84/MotionEye_04.png 460w", "/en/static/6df18c836223b4d15ab7c47b20794f56/e9beb/MotionEye_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6df18c836223b4d15ab7c47b20794f56/e9beb/MotionEye_04.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "configuring-motioneye",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-motioneye",
        "aria-label": "configuring motioneye permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring MotionEye`}</h2>
    <p>{`Open the side menu and select the camera you want to edit from the drop down menu `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` - activate the `}<strong parentName="p">{`Advanced Settings`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2`}</code>{`. Let's go through a few interesting menus that MotionEye offers:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/77b518c319eddcf4b25cc4aa567eddde/e9beb/MotionEye_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACDElEQVQoz12SS5LcQAhEdZ4WBUVRFNRXanWPw174CPb9j+HQjL3xWxLBJzPZnq/38/U+jo/3x/e1prlrP2ptrVXzqv2wfiz1lKSottb6XDaW9lO8b5ySmWWzmDJnZxuwP8x8zaH9pFwjc0QEgBjpnmn+bj1bBQhbjHGMXmqPNlkKxQQARHQ9n9YniRIhhAAAqjrnFC0MEOCubcxc3VNKgSWWitkDgIiMMayOnhUxBCTORSS5ezFnERaNWTdJUkox8yGZ8y2AQlBVd7fa6mOHfUckLh4jmdk8Tmsz2uBSt5zz+3qt6802QiqZWJjNvfdu7iPlVGpS51zcrNYqaknNrH6LfDcf62itYYBADBS/NN9GeIvhPjp80lo7juXjpGykjZA2ACia++uH//wVkALsAUnk1iJakAg/AQB3v66rHRcmA+KAuFFkwsDttJ+/KWWimKyLZHf31lk0lfvmyEKIMdK9U9sdQeQtfrrdWzWVbDVmCwCPx6O3Ns7XDgH2ByJytizSe/fa6lg+nivlLYRQ3ccYYy4RIdjxDie0WsfxlKws+csIVX2e57g+YmlUekW6N885+1gxF2AxYlGTnFurtY8xD+tTpRBiSklSiqK3NSHsiBsiupm1QblS9pAyADDzsVaby9eT+/kCTPu+wxd7+MdGRMyMRBAw4N9gVPX1uuZ59ePC7DEmvP/xf/4AlOasY2ZihUkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77b518c319eddcf4b25cc4aa567eddde/e4706/MotionEye_05.avif 230w", "/en/static/77b518c319eddcf4b25cc4aa567eddde/d1af7/MotionEye_05.avif 460w", "/en/static/77b518c319eddcf4b25cc4aa567eddde/b6582/MotionEye_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/77b518c319eddcf4b25cc4aa567eddde/a0b58/MotionEye_05.webp 230w", "/en/static/77b518c319eddcf4b25cc4aa567eddde/bc10c/MotionEye_05.webp 460w", "/en/static/77b518c319eddcf4b25cc4aa567eddde/87ca7/MotionEye_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/77b518c319eddcf4b25cc4aa567eddde/81c8e/MotionEye_05.png 230w", "/en/static/77b518c319eddcf4b25cc4aa567eddde/08a84/MotionEye_05.png 460w", "/en/static/77b518c319eddcf4b25cc4aa567eddde/e9beb/MotionEye_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/77b518c319eddcf4b25cc4aa567eddde/e9beb/MotionEye_05.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`3`}</code>{` MotionEye gives you both a administrator and a regular user account. You should add a password for the admin account here. The regular user will not have access to this part of the menu.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`4`}</code>{` We setup MotionEye to grab the 1080p RTSP stream from our camera. Here you can scale down the resolution - to save storage and - of particulate interest if you are using a Raspberry Pi to run MotionEye - to reduce the demand on your CPU. The framerate here is set to 2fps by default - which means that you end up with very choppy video. It again depends on your CPU and the amount of cameras you want to add if you might be able to go higher. You can also rotate your video image in 90 degree increments.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`5`}</code>{` Set an upper and lower detection threshold for the integrated motion detection. How much percent of the image has to change to register as an alarm? And do you want to discard the alarm, when more than a set percentage of the image changes - as it will probably just be a light that turned on and does not interest you.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`6`}</code>{` Do you have a constant movement in the background of your image that keeps triggering the alarm? Use the mask feature to have MotionEye learn which part of the image it should not use for the motion detection.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`7`}</code>{` Have MotionEye draw a box around the change it detects inside the frame - this is useful to debug false alerts. Also looks really fancy.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`8`}</code>{` The Email notification is self-explanatory - the fields are identical to `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/SMTP_Server/"
      }}>{`SMTP server configuration`}</a>{` of your INSTAR IP camera. More interesting is the web hook function below that is identical the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarmserver`}</a>{` of your INSTAR camera - we already used this function in a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Projects/Alarm_Recording_and_Nightvision/"
      }}>{`Node-RED`}</a>{` project.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`9`}</code>{` Make sure to set the video recording to `}<strong parentName="p">{`h.264 (.mp4)`}</strong>{` if you want to display the recorded video in the MotionEye user interface! Otherwise you can use the `}<strong parentName="p">{`HEVC (.mp4)`}</strong>{` to apply the highly effective h.265 compression and make your videos as small as possible.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`10`}</code>{` Choose between a continuous recording and a recording triggered by motion detection. Alarm videos will continue to record as long as the motion continues. It makes sense to limit the maximum file size - this will create more small videos instead of one huge one that is hard to handle. You can also choose to automatically delete files again after a set time.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`11`}</code>{` Upload your alarm videos to an `}<strong parentName="p">{`FTP`}</strong>{` or `}<strong parentName="p">{`SFTP Server`}</strong>{`. Or mirror them to `}<strong parentName="p">{`Dropbox`}</strong>{` or `}<strong parentName="p">{`Google Drive`}</strong>{`.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`12`}</code>{` MotionEye re-streams all your camera's video streams in a browser compatible format. We already used this feature to `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/"
      }}>{`embed a video in the ioBroker dashboard`}</a>{`. You should disable this feature if you only plan on using the MotionEye interface.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`13`}</code>{` A photo camera icon will appear in the camera viewport when you activate the `}<strong parentName="p">{`Manual Capture`}</strong>{`, allowing you to take a quick snapshot.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "accessing-motioneye-over-https",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessing-motioneye-over-https",
        "aria-label": "accessing motioneye over https permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Accessing MotionEye over HTTPS`}</h2>
    <p>{`If you want to access the MotionEye interface over the internet, it necessary to first protect your server. In the following we want to do two things:`}</p>
    <ol>
      <li parentName="ol">{`Close all unnecessary ports with a firewall`}</li>
      <li parentName="ol">{`Install a web proxy that enables us to add SSL encryption`}</li>
    </ol>
    <p>{`We are going to use `}<a parentName="p" {...{
        "href": "https://firewalld.org"
      }}>{`FirewallD`}</a>{` as firewall and `}<a parentName="p" {...{
        "href": "http://nginx.org/"
      }}>{`NGINX`}</a>{` as web proxy. Running behind the Nginx webserver (using Nginx as a reverse proxy) can be useful when:`}</p>
    <ul>
      <li parentName="ul">{`you want to use HTTPS with motionEye`}</li>
      <li parentName="ul">{`you want to serve multiple websites (including motionEye) on the same webserver (accessible at the same IP address over port 80)`}</li>
      <li parentName="ul">{`you want to take advantage of various Nginx modules (such as rate limiting or HTTP authentication)`}</li>
    </ul>
    <h3 {...{
      "id": "installation-of-firewalld-on-debian",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installation-of-firewalld-on-debian",
        "aria-label": "installation of firewalld on debian permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation of FirewallD on Debian`}</h3>
    <p>{`Install FirewallD by running the following commands:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` update
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` firewalld`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/e9beb/MotionEye_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACKUlEQVQoz2WSW1PbMBCFHSdQKJArhBCghOAkviXEkuX4ItmSLMuxnbZPPPX//4/WTpm2dObMzj7sN3PO7iqaKF9E8Q3zH1RmWPRQPA75tc8HOzrwkpuAjXZxF0ZXIDrf+ooO/tEzlc8sf2ByTMSU5nNeLuVhkVUzXmqi7m9D1kfkxPY6FmoY+AfW0mLB90ZWPsZi6NO+i0/XXstwVQOpptsykbICytKp6wp8hC1ZbbNqHovrgPY9cr72VL2ZXh7rtiGdj4aPWopynRYrls9YPhflpDE/pfl9LO9J9kTlFIvRLum5+NIJ/8vM8heWOyTdBHQdMpBkDpV2Iu+IfEzkl0R2IfnshHUEA77bfte6+q5n1Rvfv0Xs4BEJQ2y7WwPUthdOrWXT61BZNfobXvFcp3LJ8gcqxyGbRnzkJyev/oWDr2B8uvFVa6eaXtv02k1TL9JALd2tXTiiAFll8+I1KzWczuNsHosZlZMofWLFlMg7LCeRGAX8Mf16h7NJJM42gWo2MBDFmuUbUWhRegvCPgy72+CThTq219wGqpbXMtFRigEV06tTHM+25YUnK0dWMD+A/GDKSuN7jdXfchuyG5+OdmwSiZuATZpvuXSCCyc82/g1b7M684KkqySbRXyeFj2EBxAPvHiISA/iccCuQNix0KmNTmzUsVDHctu1bVexRamzvcX3pigJSQ+YOy4eIjJApO+SLsQ9iLsQn7/ufv+ZDpqdg18RfgLOLoaaMd6RcQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/e4706/MotionEye_06.avif 230w", "/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/d1af7/MotionEye_06.avif 460w", "/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/b6582/MotionEye_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/a0b58/MotionEye_06.webp 230w", "/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/bc10c/MotionEye_06.webp 460w", "/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/87ca7/MotionEye_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/81c8e/MotionEye_06.png 230w", "/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/08a84/MotionEye_06.png 460w", "/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/e9beb/MotionEye_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2acc35b1dd584e2fafeb23d95f3f7e5e/e9beb/MotionEye_06.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Führen Sie die folgenden Befehle aus, um die Standardports für `}<strong parentName="p">{`http`}</strong>{` und `}<strong parentName="p">{`https zu öffnen`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`firewall-cmd --permanent --zone`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`public --add-service`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`https --add-service`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`http
firewall-cmd --reload
firewall-cmd --list-all`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d43ffe32cd137ff9a9081d6c44b2704/e9beb/MotionEye_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACdUlEQVQozy2SaXPTSBCG9WELYkkzI4ck5KAWQwKss7Ekz0wfc1mHHZLgkILl7/Djt+Tdb11d/bx9vJ3VP38sdvsvuDXpa73ZNqk3w/bvkG5dWPp0F4dVGv7icOtjG7tTCiWG0jqBIbc+u9u/fNjtL4ZvJ2mUHEv0kuPMwJE2OWAOVKArJ8bNLEjkiqNEVhwFhexmuL/b/7zsn97195epO2WniBU7YUEBztkfc6zIVeilRQVYkVPoBJAEzhbj/ubr9zfcn7n0NnUnIQqgkriwKLStiOcuVeQFsLAkDEigipNEJ6zNFtu9/ufX8unbHINCrigojoWF0qIwOJVOfBSWilYLbaQFeegvgbLz9LB6eXm/HSU5Ra5ysXJRTYxXHBUnNeVZois0FO0EK3QKWRibfez6T7vxOrnbLnxO/nMXP/TdeUrnMZ3F9Od2p1w4jBor3kxr4xQr9BO8fNh+fLiPMT2GOPg4etezK+90rm1pbGFsbkAYOGzkp2AaKsw5SovZ8un5atzZNHre1NrV4DS4Uw2CeE6+tPS6NsVBaNaYWWPk/0JRAWc3u735/mMx7K58f7x2ZUuidXOIZ+QleIlBWFTEhYbXjRHAavKMFJBCzpb9/Wq3f8v9leuuuLukzTvXXYTx93IdV/SHDfPD5SS5clIJczdUvPnPs2y1fTRPL9dxlJpLzVXjigYLQ6olpTcCB4Wh4jh3GwEgABX6itNkNWC2iGP9/Hz9+DgzJFdw3DhVT2TZkFpBbn2BvUSvkGetKdamNFYYnJ7MUHbhusvYnwTMNYq1L9ZU1aBaVjWqmoo1yNZJ6HLNr2o8aijXvjCphM3R2v8Lt2yUt6DliPkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d43ffe32cd137ff9a9081d6c44b2704/e4706/MotionEye_07.avif 230w", "/en/static/7d43ffe32cd137ff9a9081d6c44b2704/d1af7/MotionEye_07.avif 460w", "/en/static/7d43ffe32cd137ff9a9081d6c44b2704/b6582/MotionEye_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d43ffe32cd137ff9a9081d6c44b2704/a0b58/MotionEye_07.webp 230w", "/en/static/7d43ffe32cd137ff9a9081d6c44b2704/bc10c/MotionEye_07.webp 460w", "/en/static/7d43ffe32cd137ff9a9081d6c44b2704/87ca7/MotionEye_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d43ffe32cd137ff9a9081d6c44b2704/81c8e/MotionEye_07.png 230w", "/en/static/7d43ffe32cd137ff9a9081d6c44b2704/08a84/MotionEye_07.png 460w", "/en/static/7d43ffe32cd137ff9a9081d6c44b2704/e9beb/MotionEye_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d43ffe32cd137ff9a9081d6c44b2704/e9beb/MotionEye_07.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you now try to access the MotionEye interface on port `}<strong parentName="p">{`8765`}</strong>{` you will see that it is going to timeout - as the request will be blocked by FirewallD. We can temporarily open all ports that MotionEye uses - in my case this is the main UI port 8765 and the streaming ports for three cameras `}<strong parentName="p">{`8081`}</strong>{`, `}<strong parentName="p">{`8082`}</strong>{`, `}<strong parentName="p">{`8083`}</strong>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`firewall-cmd --permanent --zone`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`public --add-port`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8765`}</span>{`/tcp --add-port`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8081`}</span>{`-8083/tcp
firewall-cmd --reload
firewall-cmd --list-all`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8114171d8a1bd7d60007b0addbfd0472/e9beb/MotionEye_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABVElEQVQY0x2P62oTQRhAF7Gy2bnuDwuiFCHGKnav8912Z5PJtjFpS0JpK/h+PqmkD3AO52Q3zydI6TVtnlJ6iPHvnBqgi6bXARSgJlZAjtgP0ctkAR1xKdHzaACz5eFwPd+FzfwDh++BV0Cf61B04IAsgOpD3gYdwBEbINWBI/EcS1lb5AyeX5e7/Zc4f4TJtKyCmDB4loWMF+P6PXDRBg246MKHqlcd2IAO2fPoiLPm/vR1u1vOu+vtfjXvf94e6rvjp/3jnxb+ffv1u4WcpRRZdFD06Dh63lgUc+4fsnh6ae6fLmFw/Vh2g2/JBla8ueniSyWrXoxMpUyOhzPAox+2XpIBMohZ/3isHo4ljEVHZTu4RnSLuuO85neYct6eAZm8RBPAAFmSN8XaImVX6fZqNylhVWFZs2/YNWRa9hXaGgucNK4tiQbKm/OzDqADORIV6D/LaEpS4awC8gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8114171d8a1bd7d60007b0addbfd0472/e4706/MotionEye_08.avif 230w", "/en/static/8114171d8a1bd7d60007b0addbfd0472/d1af7/MotionEye_08.avif 460w", "/en/static/8114171d8a1bd7d60007b0addbfd0472/b6582/MotionEye_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8114171d8a1bd7d60007b0addbfd0472/a0b58/MotionEye_08.webp 230w", "/en/static/8114171d8a1bd7d60007b0addbfd0472/bc10c/MotionEye_08.webp 460w", "/en/static/8114171d8a1bd7d60007b0addbfd0472/87ca7/MotionEye_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8114171d8a1bd7d60007b0addbfd0472/81c8e/MotionEye_08.png 230w", "/en/static/8114171d8a1bd7d60007b0addbfd0472/08a84/MotionEye_08.png 460w", "/en/static/8114171d8a1bd7d60007b0addbfd0472/e9beb/MotionEye_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8114171d8a1bd7d60007b0addbfd0472/e9beb/MotionEye_08.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should now be able to access the MotionEye interface again - `}<strong parentName="p">{`be aware`}</strong>{` that this command did not use the `}<strong parentName="p">{`--permanent`}</strong>{` flag. That means, when you restart your server, or reload the firewall, those rules will be discarded.`}</p>
    <h3 {...{
      "id": "installation-of-nginx-on-debian",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installation-of-nginx-on-debian",
        "aria-label": "installation of nginx on debian permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation of NGINX on Debian`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` nginx`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cb2fb17158a469122c6444159632c60f/e9beb/MotionEye_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB00lEQVQozyWR2VLjQAxF/Tg1JCGENRQDFMMOIRB3u70HE6C9xHv8MP//IWcq5kkqqXSkq2s8VP/QuqJardEfms9ohfaXnEubX0IxkoqR5TG2Q3aVx0BYjCy7z0eWy0A6/DYVO2IbHYybtOI2a7lYV5wmGaffmoskYd8LOHJdpmHANAwZK4ex7bNruT9AqfraxAnYs72+N7Q8jHleMys6FtUGUddYbY3d1Vhdy6IpkG2J3FTMyxyzrVk0Na9VybxIeSlS7pKYP1HEgeszlDbGoup4zltmecmN1tzFmstVxG0acxVrzqN3Lj4irr41N3HC1dcX11ozDUIOHbd/yVA6DIRiIATGLG+YVzVi0/JWl8yKnPss64HX+osDx2WiFIeex3Gw5MhfMlY2E2Wzr2ymQcBJEPYXDoTEMJsGq/sByk2N6hrMtkVsakRbIduGRVv3i17rHNk1vJY58zxjvk65jN458T32HZehsDC2w09ZzEuR8ZynvG0q7vKChzTmMU24T1P+xjEPRclTnnH5ueIs+mAavrNn2ewsRO/2SDrsLEyMl7LieZ3wlCe8JRqdaL7XPmeey7Hnc7KVFAYcL5ccBWEvb+L8ODowBbvSYrx1WXkMTcF/zOkIpFVnHsgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb2fb17158a469122c6444159632c60f/e4706/MotionEye_09.avif 230w", "/en/static/cb2fb17158a469122c6444159632c60f/d1af7/MotionEye_09.avif 460w", "/en/static/cb2fb17158a469122c6444159632c60f/b6582/MotionEye_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cb2fb17158a469122c6444159632c60f/a0b58/MotionEye_09.webp 230w", "/en/static/cb2fb17158a469122c6444159632c60f/bc10c/MotionEye_09.webp 460w", "/en/static/cb2fb17158a469122c6444159632c60f/87ca7/MotionEye_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb2fb17158a469122c6444159632c60f/81c8e/MotionEye_09.png 230w", "/en/static/cb2fb17158a469122c6444159632c60f/08a84/MotionEye_09.png 460w", "/en/static/cb2fb17158a469122c6444159632c60f/e9beb/MotionEye_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cb2fb17158a469122c6444159632c60f/e9beb/MotionEye_09.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`At the end of the installation process, Debian 9 starts Nginx. The web server should already be up and running. We can check with the systemd init system to make sure the service is running by typing:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`systemctl status nginx`}</code></pre></div>
    <p>{`When you have your server's IP address, enter it into your browser's address bar. You should see the default Nginx landing page:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/e9beb/MotionEye_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADFklEQVQoz22Q60tTcRjHz39QltWZpqvcxUu6km4zN8+2c7bfuWznsrlNQ4goEApS0UDoRQS96H1vyqReRVCvKkq70BQMJNgU3M7mcWdu6C5n1rJmtNW2ODOkoA9fnt/393t4eH7PA42Pj4+Njd2fnIwsC0t8ODgzu/jsxdKbt0I0Go3FlgUhyPOyQrKWQqGIsDz54NHw6I2bN29AlSrlcnkhEPAHAqEwH+L5aDQqCCvz8/OiKG5nK/8D2nGCIASDwTDP83x4dXU1HIn4/YFEIlEsFreq5PP5QuHH/4tT6VQ6k0klU8lkMp5ISJKUTic3spn8t2+5XG5zczOXy2193/qnuFypbGttfU2MxeLxeCwWE8WYsLIiiutRUZKyG1++5jMblfyW/PnyX4J25slKmXQymUmn5dbJ9U2ZwqfPvwqFX6VSqVgs/fxZKpVK5SrbJ3TlY+riXPz8XLz//Yr33XK/T/C+i1z6kBhZ3BheyA4vZocC0tWAtBO3zbUFacSfge48fY4PXacHR9yXx6gLV7GLQ/jg6K2HTyZez05M++5NzdyXzczElG9iynf31R9NTvtuP34J6RyudrvzFEZ2YWQXQQ9YyUEL6CCYVoptwWmNjVLbMC2g2khWC2i42wwbDHv1pl0nkZrTJkhLch2Mu43xdHDeJtptN9ouGLFWxqPBmRbKqSXYFoprJrmjjl4VoJUodRjDG0xWpYVQWghIx3qOcd7jnOdEb38n54Ux+z7McdjqaLCQB01kvYk4UvVKlGq0UErU3mjGG83gCIorLTaoEaXUBNvBuE+5zx1jvTq6t4XgmgAN94B6E1FvIvZ3WxvMJGwEdQihQHAFQhwwgt16dLcehXadMe/pQnWMR9830E73djr7Orm+Tlf/AYNtn8G632CtPYvBRlDbba1e5cfabqxGb6nRW6A6BK8zERqC1bGeVrvrqMPVTDrbHC4FAhTV5nUIDvcABQLgHgD32BRyBAoEh40AUgFajTNK1K4CdDPlbCa4Q5hDQ7BqnNWSnLwzklPjrAowKhvdZKM1Va/GWSVK/QYW3PeSqkbrJQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/e4706/MotionEye_10.avif 230w", "/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/d1af7/MotionEye_10.avif 460w", "/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/b6582/MotionEye_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/a0b58/MotionEye_10.webp 230w", "/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/bc10c/MotionEye_10.webp 460w", "/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/87ca7/MotionEye_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/81c8e/MotionEye_10.png 230w", "/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/08a84/MotionEye_10.png 460w", "/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/e9beb/MotionEye_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fc5d1175a2c0c1f5d44036141cceeb1d/e9beb/MotionEye_10.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "set-up-a-virtual-host-for-motioneye",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#set-up-a-virtual-host-for-motioneye",
        "aria-label": "set up a virtual host for motioneye permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up a Virtual Host for MotionEye`}</h4>
    <p>{`Here's the content of a sample virtual host file that normally goes to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/nginx/sites-enabled/motioneye.local`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`touch /etc/nginx/sites-enabled/motioneye.local
nano /etc/nginx/sites-enabled/motioneye.local`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d1246e437297c918182257f6ca6537d/e9beb/MotionEye_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA/UlEQVQI12O4ePP22RuHbtw5++Dy9XPnr7gnZ2l7+Wl6+is6eco7eai5+6q4esk7emh5+Wt6+Ck5e4pYOIhZOYpaOgqbWzFcvXrt4eOHt+/eefP69bWH122jEwVMbcWsnATN7MSsnIQtHITM7ITMHSRsXIQtHPhNbITM7UUtHYXM7MUszRmeP3367tWbd2/efPjw4dOHT/oBEQxKuqw6ZoyaJkzapiy65pyGNpyGNkzapoyaJowaxoyaxgzqhkzapkzaZgxXN22/unHzg/0HPrx9+/rNa8PASAZVA3Y9CzZdc3Z9S04DKw4wYtezYNUxY9Y2BSEtE1Zdc2ZtUwAJj1Nkc7oOTgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d1246e437297c918182257f6ca6537d/e4706/MotionEye_11.avif 230w", "/en/static/4d1246e437297c918182257f6ca6537d/d1af7/MotionEye_11.avif 460w", "/en/static/4d1246e437297c918182257f6ca6537d/b6582/MotionEye_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d1246e437297c918182257f6ca6537d/a0b58/MotionEye_11.webp 230w", "/en/static/4d1246e437297c918182257f6ca6537d/bc10c/MotionEye_11.webp 460w", "/en/static/4d1246e437297c918182257f6ca6537d/87ca7/MotionEye_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d1246e437297c918182257f6ca6537d/81c8e/MotionEye_11.png 230w", "/en/static/4d1246e437297c918182257f6ca6537d/08a84/MotionEye_11.png 460w", "/en/static/4d1246e437297c918182257f6ca6537d/e9beb/MotionEye_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d1246e437297c918182257f6ca6537d/e9beb/MotionEye_11.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`motioneye.local`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`server {
    listen 80;
    listen [::]:80;
    location /dashboard/ {
        proxy_pass            http://127.0.0.1:8765/;
        proxy_read_timeout    1800;
        proxy_connect_timeout 1800;
        access_log off;
    }
}`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c742b4d964ec7d4b97cce49e47a2bdda/e9beb/MotionEye_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABQElEQVQY0yWOX0+CUADFr6IoinMu66GsVMwRcPmjXOACASJCGK350jIR0Ob6/t+glO08nZ3fOQckp18/yxdp5u32QX4Ii2NYHIOsCPIi/jn5ae5sU/trZ35+O9vU2ab+Pg+LQ3Q4BlkOKBkP7OAGe52Zde+Gfd3ta25HsQgegakCJhJgIBhDMIKAEc8aQ8BIZ3/IAxIak2DNRu8PXiglGzZMmGV8574++nEXOR3ZbAk6LZld1W6KekMyKNlsSpgUdYJXASGglmwxy/gpWAvrj8HLarx862oeTDbDRUQLmOYMWsRXutuAWh3qdahXObUmaICdgZqgUTIe+THjx9MwuVyIesi5tVc9zSEEjRAQeJ5X2HnJVDmV4BHBowo7A9dYI0VMybilmG3Fas9tUjTOIR7VLjsEj0qgyqklX1b8L/8BHqdDZHeIhYgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c742b4d964ec7d4b97cce49e47a2bdda/e4706/MotionEye_12.avif 230w", "/en/static/c742b4d964ec7d4b97cce49e47a2bdda/d1af7/MotionEye_12.avif 460w", "/en/static/c742b4d964ec7d4b97cce49e47a2bdda/b6582/MotionEye_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c742b4d964ec7d4b97cce49e47a2bdda/a0b58/MotionEye_12.webp 230w", "/en/static/c742b4d964ec7d4b97cce49e47a2bdda/bc10c/MotionEye_12.webp 460w", "/en/static/c742b4d964ec7d4b97cce49e47a2bdda/87ca7/MotionEye_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c742b4d964ec7d4b97cce49e47a2bdda/81c8e/MotionEye_12.png 230w", "/en/static/c742b4d964ec7d4b97cce49e47a2bdda/08a84/MotionEye_12.png 460w", "/en/static/c742b4d964ec7d4b97cce49e47a2bdda/e9beb/MotionEye_12.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c742b4d964ec7d4b97cce49e47a2bdda/e9beb/MotionEye_12.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure that your NGINX configuration passes the test and reload the service:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`nginx -t
service nginx reload`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8d7459699304955200022af2387be1e6/e9beb/MotionEye_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA40lEQVQI1wXB206DMAAAUBxsQ3xUEy+J2RAHtFxmS7kXZJRCAVdcFuP/f4nnKHCUcDi/VWzf8PeGWw07MO7w0Wz7+4Q+xPQ5q1/y+iktjSDWw1QFeOWgDcCqi5Ti9y+5XO1OHGdp8/mDiXBeUnltJkmZgN1kDWdnXF5pp0Gy9mIVkpWLNYA1GClY/rh8BP0Eh9k69bua70/C76alFZeKFbRFZRsU1WOcGCHZ+qkephokNwBvg0RBcgmmb4cL0I/mF9vV7MAn0IlPmpkos6PSwdRCueGRO5TfHrO1TzRINhDpXvQPyeMwiXnWn54AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d7459699304955200022af2387be1e6/e4706/MotionEye_13.avif 230w", "/en/static/8d7459699304955200022af2387be1e6/d1af7/MotionEye_13.avif 460w", "/en/static/8d7459699304955200022af2387be1e6/b6582/MotionEye_13.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8d7459699304955200022af2387be1e6/a0b58/MotionEye_13.webp 230w", "/en/static/8d7459699304955200022af2387be1e6/bc10c/MotionEye_13.webp 460w", "/en/static/8d7459699304955200022af2387be1e6/87ca7/MotionEye_13.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d7459699304955200022af2387be1e6/81c8e/MotionEye_13.png 230w", "/en/static/8d7459699304955200022af2387be1e6/08a84/MotionEye_13.png 460w", "/en/static/8d7459699304955200022af2387be1e6/e9beb/MotionEye_13.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8d7459699304955200022af2387be1e6/e9beb/MotionEye_13.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your MotionEye UI will be available at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.111/dashboard/`}</code>{`  (`}<strong parentName="p">{`this has to be changed to the IP address of your MotionEye server!`}</strong>{`). It's important to note the trailing slashes at location /dashboard/ and at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://127.0.0.1:8765/`}</code>{`. They make sure paths are correctly passed around when forwarding the HTTP requests to motionEye.`}</p>
    <h4 {...{
      "id": "create-the-ssl-certificate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#create-the-ssl-certificate",
        "aria-label": "create the ssl certificate permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create the SSL Certificate`}</h4>
    <p>{`We can create a self-signed key and certificate pair with OpenSSL in a single command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl req -x509 -nodes -days `}<span parentName="code" {...{
            "className": "token number"
          }}>{`365`}</span>{` -newkey rsa:2048 -keyout /etc/ssl/private/nginx-selfsigned.key -out /etc/ssl/certs/nginx-selfsigned.crt`}</code></pre></div>
    <p>{`These options will create both a key file and a certificate. We will be asked a few questions about our server in order to embed the information correctly in the certificate.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4abe5140685c1da5ba2517e05e274969/e9beb/MotionEye_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAB40lEQVQozz2RaU8iQRCGR924rhBRPPDIhkXDjcIcMM3QzAXMAIOom+z//ynPpkvjh05VuqufeustK00ywsWGiU6ZxRlJVhAvtwRxhk4y/PmSsV4w8DTPnsYNEoZ+iK0iHBVJ9HVK3w+Jhz7W7XxFbbagMp5zNU2o6QUXfsT5JORiElJVMWVnxslwSsnRlFzNyVBhdRystoPVdSU/7I+xeh7WXZhxpWKqfsSlSiSveJqqH1N2NeVJyLlKuA0zTO31NOFSRZx9gQ+6Lscvih/9MUcGOnr/R2OxobPZ87z7y9Nyy6B453ecczNLudJL7uKchzDjaVVIrM1SrlUs4ONnX0CHPY8jo7Cz/aCerPmTbnha7SR/XGwEaODmdNZ7Kt5cbDjz9Cek532rEpiJZvzH1Su1IJVRr4OUhygXBebuJkgEYGCno0A+HXSNik/fJBof27ZAzbF62zca6ZrB7oN+8UErfxWF3fWeVr6Tt3a+o57mVFVEZRxKg7IdyLJKdkDJnn41cLBaWUFzVdDMXmkst9STnOZqSzPfiwWPy0LGflApjXlOPcypuJrT0VRgv4aKkxf/a+s2lik2gPb6jXtjfpSJwnq64T7MqScpJdvlqOVxOlD8HPgcytiObNhq2Vit0bfC/xb1F4kUKwRKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4abe5140685c1da5ba2517e05e274969/e4706/MotionEye_14.avif 230w", "/en/static/4abe5140685c1da5ba2517e05e274969/d1af7/MotionEye_14.avif 460w", "/en/static/4abe5140685c1da5ba2517e05e274969/b6582/MotionEye_14.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4abe5140685c1da5ba2517e05e274969/a0b58/MotionEye_14.webp 230w", "/en/static/4abe5140685c1da5ba2517e05e274969/bc10c/MotionEye_14.webp 460w", "/en/static/4abe5140685c1da5ba2517e05e274969/87ca7/MotionEye_14.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4abe5140685c1da5ba2517e05e274969/81c8e/MotionEye_14.png 230w", "/en/static/4abe5140685c1da5ba2517e05e274969/08a84/MotionEye_14.png 460w", "/en/static/4abe5140685c1da5ba2517e05e274969/e9beb/MotionEye_14.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4abe5140685c1da5ba2517e05e274969/e9beb/MotionEye_14.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Both of the files you created will be placed in the appropriate subdirectories of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/ssl`}</code>{` directory.`}</p>
    <p>{`While we are using OpenSSL, we should also create a strong Diffie-Hellman group, which is used in negotiating `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Forward_secrecy"
      }}>{`Perfect Forward Secrecy`}</a>{` with clients.`}</p>
    <p>{`We can do this by typing:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl dhparam -out /etc/ssl/certs/dhparam.pem `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2048`}</span></code></pre></div>
    <p>{`This may take a few minutes, but when it's done you will have a strong DH group at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/ssl/certs/dhparam.pem`}</code>{` that we can use in our configuration.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6f2c840aa7ddba795623a4d11c085c6f/e9beb/MotionEye_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "8.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAAiElEQVQI1x3Lyw6CMABE0RKEnQuDiUuXakCiKA8tWEotgq/E//+Ya+jiZCaTjNg83qz1wL1/8bFPbNvzG7/oukNJjVXWba3sGM3gNJVCljfStERsM/w4d+ntToiVNESlYlkb5hdN1FgW0hBmEv94JcgkXlo5U/cPV0RSIKZzfCZICmcW54T7kj+omj6Sak1v+AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f2c840aa7ddba795623a4d11c085c6f/e4706/MotionEye_15.avif 230w", "/en/static/6f2c840aa7ddba795623a4d11c085c6f/d1af7/MotionEye_15.avif 460w", "/en/static/6f2c840aa7ddba795623a4d11c085c6f/b6582/MotionEye_15.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6f2c840aa7ddba795623a4d11c085c6f/a0b58/MotionEye_15.webp 230w", "/en/static/6f2c840aa7ddba795623a4d11c085c6f/bc10c/MotionEye_15.webp 460w", "/en/static/6f2c840aa7ddba795623a4d11c085c6f/87ca7/MotionEye_15.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f2c840aa7ddba795623a4d11c085c6f/81c8e/MotionEye_15.png 230w", "/en/static/6f2c840aa7ddba795623a4d11c085c6f/08a84/MotionEye_15.png 460w", "/en/static/6f2c840aa7ddba795623a4d11c085c6f/e9beb/MotionEye_15.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6f2c840aa7ddba795623a4d11c085c6f/e9beb/MotionEye_15.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "configure-nginx-to-use-ssl",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#configure-nginx-to-use-ssl",
        "aria-label": "configure nginx to use ssl permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure Nginx to Use SSL`}</h4>
    <p>{`We have created our key and certificate files under the /etc/ssl directory. Now we just need to modify our Nginx configuration to take advantage of these.`}</p>
    <p>{`We will make a few adjustments to our configuration:`}</p>
    <ul>
      <li parentName="ul">{`We will create a configuration snippet containing our SSL key and certificate file locations.`}</li>
      <li parentName="ul">{`We will create a configuration snippet containing strong SSL settings that can be used with any certificates in the future.`}</li>
      <li parentName="ul">{`We will adjust our Nginx server blocks to handle SSL requests and use the two snippets above.`}</li>
    </ul>
    <p>{`First, let's create a new Nginx configuration snippet in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/nginx/snippets`}</code>{` directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/nginx/snippets/self-signed.conf`}</code></pre></div>
    <p>{`Within this file, we just need to set the ssl_certificate directive to our certificate file and the ssl_certificate_key to the associated key. In our case, this will look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`ssl_certificate /etc/ssl/certs/nginx-selfsigned.crt;
ssl_certificate_key /etc/ssl/private/nginx-selfsigned.key;`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8410c696d0f2fee6934bdc2029ff5b7c/e9beb/MotionEye_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "9.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAgklEQVQI12NILi9NryrPrKksaWss72jOrqvOqq1KrSxLKivJrKlKLCuNLylOqSyPLylJLCvJqK4obK4raqlPLC1OKC1mELZw4DO24TWy5jW24TWxFTC1EzSzEzS1E7VyEjS1EzK359S35DA0ZlNXY5DSZJBRZ5BSZ5BWZ5DRYJDTAgDQpSN8gHGgWQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8410c696d0f2fee6934bdc2029ff5b7c/e4706/MotionEye_16.avif 230w", "/en/static/8410c696d0f2fee6934bdc2029ff5b7c/d1af7/MotionEye_16.avif 460w", "/en/static/8410c696d0f2fee6934bdc2029ff5b7c/b6582/MotionEye_16.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8410c696d0f2fee6934bdc2029ff5b7c/a0b58/MotionEye_16.webp 230w", "/en/static/8410c696d0f2fee6934bdc2029ff5b7c/bc10c/MotionEye_16.webp 460w", "/en/static/8410c696d0f2fee6934bdc2029ff5b7c/87ca7/MotionEye_16.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8410c696d0f2fee6934bdc2029ff5b7c/81c8e/MotionEye_16.png 230w", "/en/static/8410c696d0f2fee6934bdc2029ff5b7c/08a84/MotionEye_16.png 460w", "/en/static/8410c696d0f2fee6934bdc2029ff5b7c/e9beb/MotionEye_16.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8410c696d0f2fee6934bdc2029ff5b7c/e9beb/MotionEye_16.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Next, we will create another snippet that will define some SSL settings. This will set Nginx up with a strong SSL cipher suite and enable some advanced features that will help keep our server secure.`}</p>
    <p>{`The parameters we will set can be reused in future Nginx configurations, so we will give the file a generic name:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/nginx/snippets/ssl-params.conf`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "729px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d1ee79eecf48e140f5673ac60165597d/b2982/MotionEye_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACC0lEQVQozz2Qb0/aUBSH7y01w1Etk4GyoYgg5bZl9N622NvbUvqXApnZV9iMM1vMskUUQWJmXBbf7DtvZcuS58Xz5nfO+R3wcXFzeb/8+rC6fXpcPD1e3i8+rxYXy/mHm9n57fX72dXFcn5+e302n31aLeZPD3e/fnz7uTpbXn35fgdEwxEwLWi0gGn+zQmvGEAiQCIQ6RzSYQcDCcNO5pysw4YKDhXQUsGxAhoyaISjmhfXvOiVG9W9uOqEu7a/pTMB2wVM19gCsfM9KxutGrxqbvTMjZ7BqwaQxtODIG7GKZqctqJJlflV6u0YrGg6ouls66xouqLpQJmADgaIQJlAhXBKJqARjw6CeD+Iq4OgZHlCzxJUg2trUMKchNcBDGWckwlE5H/4L0AdT6UwrbvBjs7yqvmsa25rVg4RvqNtIm1DxryCoaJxclYeIAxkDJS1IAw6o0k7GCnJpO5Ge9SrOf4u9bZ0VjFZ3WI1ypqOV7MHgtZ/Ydib2kmB0AKhgm4X+w5ojtJWOmqG0VEQSul4l/n7w/i1F7WTMZlM2enbfjpBUSKFEYqTPXdY7LMSHTzHVq5rAPnddD8KO0nSTROSjJthWl5vLlmDCmVV6lQMKvatl86gzAbiCeO7Rk7VOYVkxx+EUcV1j32/7Q6R6x8NozIb1j1XNPV1PQ1mnTFA2j9kDOXs23/yvwHVS3Tjebsv7QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d1ee79eecf48e140f5673ac60165597d/e4706/MotionEye_17.avif 230w", "/en/static/d1ee79eecf48e140f5673ac60165597d/d1af7/MotionEye_17.avif 460w", "/en/static/d1ee79eecf48e140f5673ac60165597d/e92f3/MotionEye_17.avif 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d1ee79eecf48e140f5673ac60165597d/a0b58/MotionEye_17.webp 230w", "/en/static/d1ee79eecf48e140f5673ac60165597d/bc10c/MotionEye_17.webp 460w", "/en/static/d1ee79eecf48e140f5673ac60165597d/78e23/MotionEye_17.webp 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d1ee79eecf48e140f5673ac60165597d/81c8e/MotionEye_17.png 230w", "/en/static/d1ee79eecf48e140f5673ac60165597d/08a84/MotionEye_17.png 460w", "/en/static/d1ee79eecf48e140f5673ac60165597d/b2982/MotionEye_17.png 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d1ee79eecf48e140f5673ac60165597d/b2982/MotionEye_17.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# from https://cipherli.st/
# and https://raymii.org/s/tutorials/Strong_SSL_Security_On_nginx.html

ssl_protocols TLSv1 TLSv1.1 TLSv1.2;
ssl_prefer_server_ciphers on;
ssl_ciphers "EECDH+AESGCM:EDH+AESGCM:AES256+EECDH:AES256+EDH";
ssl_ecdh_curve secp384r1;
ssl_session_cache shared:SSL:10m;
ssl_session_tickets off;
ssl_stapling on;
ssl_stapling_verify on;
resolver 8.8.8.8 8.8.4.4 valid=300s;
resolver_timeout 5s;
# Disable preloading HSTS for now.  You can use the commented out header line that includes
# the "preload" directive if you understand the implications.
#add_header Strict-Transport-Security "max-age=63072000; includeSubdomains; preload";
add_header Strict-Transport-Security "max-age=63072000; includeSubdomains";
add_header X-Frame-Options DENY;
add_header X-Content-Type-Options nosniff;

ssl_dhparam /etc/ssl/certs/dhparam.pem;`}</code></pre></div>
    <p>{`Because we are using a self-signed certificate, the SSL stapling will not be used. Nginx will simply output a warning, disable stapling for our self-signed cert, and continue to operate correctly.`}</p>
    <h5 {...{
      "id": "adjust-the-nginx-configuration-to-use-ssl",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#adjust-the-nginx-configuration-to-use-ssl",
        "aria-label": "adjust the nginx configuration to use ssl permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjust the Nginx Configuration to Use SSL`}</h5>
    <p>{`Now that we have our snippets, we can adjust our Nginx configuration to enable SSL.`}</p>
    <p>{`In this guide we are using the default server block file in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/nginx/sites-available`}</code>{` directory. If you are using a different server block file, substitute its name in the below commands. Before we go any further, let's back up our current server block file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`cp`}</span>{` /etc/nginx/sites-available/default /etc/nginx/sites-available/default.bak`}</code></pre></div>
    <p>{`Now, open the server block file to make adjustments:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/nginx/sites-available/default`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "729px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b304d94d0fabe03cee865be81ca640cb/b2982/MotionEye_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA1UlEQVQY003QXUvDMBiG4SSsFQ8UoXP0QIYThs1Xm7zNmqRJamrF//+TpIVtwn168cCDXqfp4MejDx8+UJ/eLrHgUFL9wKCUsJNQCCgk4BawBCIAc8BsjXBAZzV4H611Xz7EIbhhVMa1Xc8ltEw9M41u5l9kC3GpT/l779N7yp/Tco65dqG++JL3x0bVVCMBhAHa1m7sijtT2fTYjy82VTY8gSO8J8IgBodGVVQjCmtXfPcCUCcg5cXlRdpg0mziHObfMP+okH077Bu1MnqXeGNYrC/8Ae8JLsC/IdVDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b304d94d0fabe03cee865be81ca640cb/e4706/MotionEye_18.avif 230w", "/en/static/b304d94d0fabe03cee865be81ca640cb/d1af7/MotionEye_18.avif 460w", "/en/static/b304d94d0fabe03cee865be81ca640cb/e92f3/MotionEye_18.avif 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b304d94d0fabe03cee865be81ca640cb/a0b58/MotionEye_18.webp 230w", "/en/static/b304d94d0fabe03cee865be81ca640cb/bc10c/MotionEye_18.webp 460w", "/en/static/b304d94d0fabe03cee865be81ca640cb/78e23/MotionEye_18.webp 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b304d94d0fabe03cee865be81ca640cb/81c8e/MotionEye_18.png 230w", "/en/static/b304d94d0fabe03cee865be81ca640cb/08a84/MotionEye_18.png 460w", "/en/static/b304d94d0fabe03cee865be81ca640cb/b2982/MotionEye_18.png 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b304d94d0fabe03cee865be81ca640cb/b2982/MotionEye_18.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We will be modifying this configuration so that unencrypted HTTP requests are automatically redirected to encrypted HTTPS. This offers the best security for our sites. If you want to allow both HTTP and HTTPS traffic, use the alternative configuration that follows.`}</p>
    <p>{`Next, we need to start a new server block directly below to contain the remaining configuration. We can uncomment the two listen directives that use port 443. We can add http2 to these lines in order to enable HTTP/2 within this block. Afterwards, we just need to include the two snippet files we set up:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`server `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	listen `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{` default_server`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  listen `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`::`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:80 default_server`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`301`}</span>{` https://`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$server_name`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$request_uri`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SSL configuration`}</span>{`
	`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#`}</span>{`
	listen `}<span parentName="code" {...{
            "className": "token number"
          }}>{`443`}</span>{` ssl http2 default_server`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	listen `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`::`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:443 ssl http2 default_server`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	include snippets/self-signed.conf`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	include snippets/ssl-params.conf`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "729px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d0f57cf820cba6222d738a3378889bf1/b2982/MotionEye_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.304347826086953%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABAElEQVQY023PXW+CMBQG4BbY9AKmZolD3EcmukxKcZyW7wq2GNj4/z9oKezCiyVv3pybJ+cc5NbNQ1rYLFknmcfzNWRWxE0SzyizKJghTI2nEMCBbmMMmn+ly6w89N3+IiPVBULtarnJqlXE5wQsMsrJ3DCD6BmhILZZ/txcvbPyK/VZXHaVdPP6MRWrVDi8tEKOAkD/4rsIbJY7rFwk1aoQTlrhgJmUmyE3CcMBoKPO7WZ9PwUcAcJhvMzE4ftn27Tvbe+33f7afwyDP3RPor6PEnz8Y9PPGk/yBAhRWOR8K6XbyBfVvlbN21l6Um1UPWPcoOORFIyTBkY4ho4+hF+peDZMM9OPHQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d0f57cf820cba6222d738a3378889bf1/e4706/MotionEye_19.avif 230w", "/en/static/d0f57cf820cba6222d738a3378889bf1/d1af7/MotionEye_19.avif 460w", "/en/static/d0f57cf820cba6222d738a3378889bf1/e92f3/MotionEye_19.avif 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d0f57cf820cba6222d738a3378889bf1/a0b58/MotionEye_19.webp 230w", "/en/static/d0f57cf820cba6222d738a3378889bf1/bc10c/MotionEye_19.webp 460w", "/en/static/d0f57cf820cba6222d738a3378889bf1/78e23/MotionEye_19.webp 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d0f57cf820cba6222d738a3378889bf1/81c8e/MotionEye_19.png 230w", "/en/static/d0f57cf820cba6222d738a3378889bf1/08a84/MotionEye_19.png 460w", "/en/static/d0f57cf820cba6222d738a3378889bf1/b2982/MotionEye_19.png 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d0f57cf820cba6222d738a3378889bf1/b2982/MotionEye_19.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we have to add the SSL configuration to our MotionEye server block in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/nginx/sites-enabled/motioneye.local`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`server {
    listen 80;
    return 301 https://$server_name$request_uri;
}

server {
    listen 443 ssl http2;
	  server_name 192.168.2.111;
    include snippets/self-signed.conf;
    include snippets/ssl-params.conf;
    location /dashboard/ {
        proxy_pass            http://127.0.0.1:8765/;
        proxy_read_timeout    1800;
        proxy_connect_timeout 1800;
        access_log off;
    }
}`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "729px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dd616956cf7af0ed374b28ff3f281f70/b2982/MotionEye_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABZUlEQVQY002Pa0/CQBBFdymirTQC8UEMxgeg0O6jO+yWlgI2WixGoBWKH/z/P8Rsg8bkfLiTzJmbQdPv3bjYy91utN2pouDZJ6xzscr5RybXOawzvslYlnmbnK1yL9+qYi+L/fjry11tUF2qq2De8CNLqCOmTrgyqbK8wOJ+i0nL8U4GrDpgqE81PYp6RNMlOtsyPBbKFMoW/qlQNSYNoqlR1RT+BahLLhtM2lyecVllJURjUImak6j9Et8vFv0kocmiIyKbhXUe4CHUCNQp2AQsBgaHKoUKBUwAu1BxdUCmHPfS9DFNw3wzelu689fO/PU6ejZ1DxgUDKLB5KBh5yBXXEBVOrqM5oP3JV+m3VncHoU3s/hh9twmfouo8/HUFD52hHb+aQfZINDwJ3cv6W2cdOPkNpi2g1lThpUnYTjlkgOo5L+MS5ApfD04Ql8aCuQINPDQ8LfKATTU/DXj8ltMATP4AV7BTc4ja2baAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd616956cf7af0ed374b28ff3f281f70/e4706/MotionEye_20.avif 230w", "/en/static/dd616956cf7af0ed374b28ff3f281f70/d1af7/MotionEye_20.avif 460w", "/en/static/dd616956cf7af0ed374b28ff3f281f70/e92f3/MotionEye_20.avif 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dd616956cf7af0ed374b28ff3f281f70/a0b58/MotionEye_20.webp 230w", "/en/static/dd616956cf7af0ed374b28ff3f281f70/bc10c/MotionEye_20.webp 460w", "/en/static/dd616956cf7af0ed374b28ff3f281f70/78e23/MotionEye_20.webp 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd616956cf7af0ed374b28ff3f281f70/81c8e/MotionEye_20.png 230w", "/en/static/dd616956cf7af0ed374b28ff3f281f70/08a84/MotionEye_20.png 460w", "/en/static/dd616956cf7af0ed374b28ff3f281f70/b2982/MotionEye_20.png 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dd616956cf7af0ed374b28ff3f281f70/b2982/MotionEye_20.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we can restart Nginx to implement our new changes:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`nginx -t`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "729px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7aca4b2f11df4bb4b21e913bd95503c2/b2982/MotionEye_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.608695652173912%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAqElEQVQI1y3L3QqCMBgA0GnQRST9aZD2Z0SoFem23IZ+mzlnGr3/80TUuT/oqPt90wnVKNVQJn2u5kxNaTmh4PHK5dVSPGZ3sOPMilI7xijGVkLsMxlcCQp1u63bW90L3aegt6CPutspE9bPAPRamgDMRtYeo35xH15S9PtnbF8wOpgu6t+nFzi5GJHCYxBwteLKZXKRw5TChJZjnI9J5pDMSrJ/Tr7/A9ebH60JqSFmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7aca4b2f11df4bb4b21e913bd95503c2/e4706/MotionEye_21.avif 230w", "/en/static/7aca4b2f11df4bb4b21e913bd95503c2/d1af7/MotionEye_21.avif 460w", "/en/static/7aca4b2f11df4bb4b21e913bd95503c2/e92f3/MotionEye_21.avif 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7aca4b2f11df4bb4b21e913bd95503c2/a0b58/MotionEye_21.webp 230w", "/en/static/7aca4b2f11df4bb4b21e913bd95503c2/bc10c/MotionEye_21.webp 460w", "/en/static/7aca4b2f11df4bb4b21e913bd95503c2/78e23/MotionEye_21.webp 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7aca4b2f11df4bb4b21e913bd95503c2/81c8e/MotionEye_21.png 230w", "/en/static/7aca4b2f11df4bb4b21e913bd95503c2/08a84/MotionEye_21.png 460w", "/en/static/7aca4b2f11df4bb4b21e913bd95503c2/b2982/MotionEye_21.png 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7aca4b2f11df4bb4b21e913bd95503c2/b2982/MotionEye_21.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Notice the warning in the beginning. As noted earlier, this particular setting throws a warning since our self-signed certificate can't use SSL stapling. This is expected and our server can still encrypt connections correctly.`}</p>
    <p>{`If your output matches the above, your configuration file has no syntax errors. We can safely restart Nginx to implement our changes:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` nginx reload`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "729px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d75722d20a8140eea40335915dd801e4/b2982/MotionEye_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "101.30434782608697%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACKklEQVQ4y6WUy27TQBiF8zCwYMelTctLGBBdpaLsqFDfAKkLlqzoAhASL4DEjgWwJ6GtCqQtvAAExYovc5/5525kO2kopWorjo5ky/KZ+TTntztJktxdWUmSpNfr9VZX762t3bp9Z7G7fGOhu7C4tNhdPunu0s1r1xcuXb7S2djYWF9ff7y5OewPhts7w93dL4NP/cHgY3+wu/d5uH/wl78O9w+/fX/3/sODh486ZiZQqjYAKKXPIWttp/oPzcMxhIuHY6yqynP+a+upLdF0lTjX2WGXpumL53o0ahjixbCtcxCiAuCcM84AlJRSSEEpsdaeEQZQqCgwxiUqi6JglGFMMMZ5nikA773W2hhjrfXeOedauGnYGEMpFVwwSoUQvAZglFKEMCEUAAghlFLZ1CmlbM9iHiaEcsYpo5wxzrmUUmstpdBan4EdQrCNnHPtTTi9uaMKpmHnnBCy2bCmklK1Fy4ErXkpJZQxRgitqbgwxh4bEiklIcQY8+faMUYffJgpxlBV8QR2rHyoHU7puH3MIf7I3c/CjQrnwiwslEWYl4hJcP8Mtyfwdk8kT9L7z7Le1iTF/mjn6Koq1C/FxiHGaK01xjjv/YxZgE+xG5cmRdqHOB1PDZC/fFUcHBaMZZNJnuWoRFk2GY/HRVGUjfI8L8tcCaIE8RaqOAtbTPDVJfL6DfaOIMQ5QwgpqXwzUEcVGmO0sc75eKwq78n2jsxyaP8KAOf5MH4DMcdQrLICZJ0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d75722d20a8140eea40335915dd801e4/e4706/MotionEye_22.avif 230w", "/en/static/d75722d20a8140eea40335915dd801e4/d1af7/MotionEye_22.avif 460w", "/en/static/d75722d20a8140eea40335915dd801e4/e92f3/MotionEye_22.avif 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d75722d20a8140eea40335915dd801e4/a0b58/MotionEye_22.webp 230w", "/en/static/d75722d20a8140eea40335915dd801e4/bc10c/MotionEye_22.webp 460w", "/en/static/d75722d20a8140eea40335915dd801e4/78e23/MotionEye_22.webp 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d75722d20a8140eea40335915dd801e4/81c8e/MotionEye_22.png 230w", "/en/static/d75722d20a8140eea40335915dd801e4/08a84/MotionEye_22.png 460w", "/en/static/d75722d20a8140eea40335915dd801e4/b2982/MotionEye_22.png 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d75722d20a8140eea40335915dd801e4/b2982/MotionEye_22.png",
              "alt": "Motion Eye and INSTAR IP Cameras",
              "title": "Motion Eye and INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      